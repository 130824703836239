.col_box {
    background: #f54343;
    width: 100%;
    margin: 0 auto;
    height: 38px;
}

.col_navlist {
    width: 1000px;
    margin: 0 auto;
    height: 38px;
    & ul {
        background: url(./line05.jpg) left top no-repeat;
        height: 38px;
        overflow: hidden;
        & li {
            font-size: 14px;
            height: 38px;
            line-height: 38px;
            background: url(./line05.jpg) right top no-repeat;
            padding: 0 17px;
            float: left;
            & a {
                color: #fff;
            }
        }
        & a:hover {
            color:#990000;
            text-decoration: none;
            cursor: pointer;
        }
    }
}

.pa {
    padding: 0 35px;
    & a {
        color: #ffc200;
    }
}
