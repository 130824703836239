.ad {
    width: 1000px;
    margin: 30px auto 0;
}

.box {
    width: 1000px;
    text-align: center;
    box-sizing: border-box;
    position: relative;
    padding: 0;
    margin: 0;
}

.footer {
    width: 1000px;
    margin: 0 auto;
    height: 80px;
    background: none;
}
