.clearfix:after {
    content: '.';
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}

.infoConOneBox {
    padding-left: 10px;
    padding-right: 10px;
    margin-left: -10px;
    margin-top: 16px;
    width: 650px;
}

.infoConOneTxt {
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: 18px;
    position: relative;
}

.infoConOneBox_pic {
    width: 168px;
    height: 90px;
    float: left;
    margin-right: 20px;
}

.infoConOneBox h3 {
    height: 60px;
    line-height: 30px;
    overflow: hidden;
    padding-top: 15px;
}

.vBlack {
    background: url(http://p0.ifengimg.com/fe/ent_ifeng_com/images/vGray_8ac3eab5.gif) no-repeat left center;
    padding-left: 20px;
}

.vPoz {
    background-position: 0px 22px;
}

.pl {
    position: absolute;
    width: 45px;
    height: 14px;
    right: 0;
    bottom: 9px;
}

.infoConOneBox_pic a img {
    display: block;
    width: 160px;
    height: 90px;
}

.infoConOneBox h3 a {
    font-size: 18px;
    color: #000;
    font-weight: normal;
    font-family: Microsoft Yahei;
}

.pl a {
    background: url(http://y0.ifengimg.com/fe/zx1/images/ly_a9ebd58d.jpg) no-repeat 0 2px;
    width: 25px;
    height: 14px;
    display: block;
    color: #d1d1d1;
    overflow: hidden;
    padding-left: 18px;
    line-height: 14px;
    text-decoration: none;
}

.top_title {
    margin-top: 40px;
}