.fpic02 {
    width: 300px;
    height: 600px;
    position: relative;
    overflow: hidden;
}

.fpic02 .text {
    width: 300px;
    height: 125px;
    background: #000;
    position: absolute;
    bottom: 30px;
    left: 0px;
    filter: alpha(opacity=75);
    opacity: 0.75;
    z-index: 2;
}

.fpic02 .kuang {
    width: 278px;
    height: 578px;
    border: 1px solid #fff;
    position: absolute;
    left: 10px;
    top: 10px;
    z-index: 1;
    cursor: pointer;
}

.fpic02 .kuang a {
    display: block;
    width: 278px;
    height: 578px;
    cursor: pointer;
}

.fpic02 .pic_word {
    width: 260px;
    height: 110px;
    line-height: 22px;
    position: absolute;
    bottom: 30px;
    left: 0px;
    padding: 15px 20px 0;
    z-index: 3;
}

.fpic02 .pic_word h3 {
    padding-bottom: 5px;
}

.fpic02 .pic_word h3 a {
    font-size: 18px;
    color: #ffffff;
    font-family: Microsoft Yahei;
}

.fpic02 .pic_word a {
    font-size: 14px;
    color: #fff;
}

.fpic02 .pic_word a:hover {
    text-decoration: none;
}

.fpic02 [ifeng_ui_pc_sliders-dots] {
    bottom: 16px;
    text-align: center;
}

.bigPic04 {
    position: relative;
}

.bigPic04 a img {
    display: block;
}

#prevSlide02,
#nextSlide02 {
    width: 36px;
    height: 60px;
    position: absolute;
    top: 70px;
    left: 0px;
    z-index: 10;
    text-align: center;
    cursor: pointer;
}

#prevSlide02 img,
#nextSlide02 img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 12;
}

.btnBg {
    width: 36px;
    height: 60px;
    filter: alpha(opacity=60);
    -moz-opacity: 0.6;
    opacity: 0.6;
    background: #000;
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 11;
    display: none;
}

.btnBgHover {
    width: 36px;
    height: 60px;
    filter: alpha(opacity=100);
    -moz-opacity: 1;
    opacity: 1;
    background: #000;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 11;
    display: none;
}

#nextSlide02 {
    left: 365px;
}

#nextSlide02 img {
    left: 0;
}

.dotList04 {
    position: absolute;
    right: 10px;
    top: 162px;
    height: 9px;
    overflow: hidden;
    width: 88px;
}

.fpic02 [ifeng_ui_pc_sliders-dots-item] {
    width: 20px;
    height: 2px;
    margin: 0 0 0 2px;
    cursor: pointer;
    background: #d3cecb;
    overflow: hidden;
}

.fpic02 [ifeng_ui_pc_sliders-dots-item='current'] {
    background: #f54343;
}

.fpic02 [ifeng_ui_pc_sliders-arrows_next] {
    display: none;
}

.fpic02 [ifeng_ui_pc_sliders-arrows_pre] {
    display: none;
}

.cWhite a,
.cWhite a:visited,
.cWhite a:hover {
    color: #fff;
}

.fpic02 [ifeng_ui_pc_sliders-dots] div {
    width: 8px;
    height: 8px;
    margin: 0 0 0 10px;
    cursor: pointer;
    overflow: hidden;
    border-radius: 8px;
}

.current {
    background: #ff2838 !important;
}
