.bottom {
    width: 1px;
    height: 1px;
}

.clearfix:after {
    content: '.';
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}

.space10 {
    height: 10px;
    overflow: hidden;
}

.space20 {
    height: 20px;
    overflow: hidden;
}

.space28 {
    height: 28px;
    overflow: hidden;
}

.space30 {
    height: 30px;
    overflow: hidden;
}

.space40 {
    height: 40px;
    overflow: hidden;
}

.col_left {
    width: 650px;
    float: left;
}

.col_right {
    width: 300px;
    float: right;
}

.foot {
    background: url(//y0.ifengimg.com/a/2013/0930/g45.png) repeat-x #f5f5f5;
    width: 100%;
    padding-top: 2px;
}

.col_w1000 {
    width: 1000px;
    margin: 0 auto;
}

.ad_box_1 {
    width: 300px;
    height: 600px;
}

.ad_box_2 {
    width: 300px;
    height: 250px;
    /* margin-bottom: 28px; */
}

.col_title {
    background: url(http://y1.ifengimg.com/a/2014/1208/ent/y_16.jpg) left bottom no-repeat;
    padding-bottom: 11px;
    line-height: 30px;
    margin-bottom: 15px;
}

.col_w1000 .col_title a {
    font-size: 26px;
    color: #2b2b2b;
    font-family: Microsoft Yahei;
    text-decoration: none;
}

.banner_box {
    width: 1000px;
    margin: 0 auto;
    margin-bottom: 30px;
    margin-top: 30px;
    height: 90px;
}

.ad_float_1 {
    z-index: 2147483645;
    position: fixed;
    float: left;
    display: block;
    top: 100px;
    left: 1px;
    width: 120px;
    height: 315px;
}

.ad_float_2 {
    z-index: 2147483645;
    position: fixed;
    display: block;
    top: 100px;
    right: 1px;
    width: 120px;
    height: 315px;
}
