.clearfix:after {
    content: '.';
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}

.FNewVidCon {
    margin-bottom: 4px;
}

.fl {
    float: left;
}

.FVCPic {
    position: relative;
    width: 120px;
    height: 92px;
    margin-top: 7px;
    _margin-top: 6px;
    margin-right: 14px;
    _margin-right: 10px;
    overflow: hidden;
}

.FVCPicBtn {
    background: url(http://p1.ifengimg.com/ifeng/ifeng-index/20160122/spritesize47_w1000_h2000.png) 0 -900px;
    _background: url(http://p1.ifengimg.com/ifeng/ifeng-index/20160122/sprite.gif) 0 -900px;
    position: absolute;
    top: 44px;
    left: 95px;
    width: 20px;
    height: 21px;
}

.FVCTxt {
    width: 165px;
}

.FVCTxt li {
    height: 50px;
    overflow: hidden;
}

.FVCTxt li span {
    background: url(http://p0.ifengimg.com/fe/www_ifeng_com/images/iconLine_e0d0986c.gif) no-repeat right center;
    padding-right: 13px;
}

.FVCTxt li span,
.FVCTxt li span a {
    color: #f54343;
}

.FVCTxt li a {
    font-size: 14px;
    color: #004276;
    line-height: 26px;
}

.FVCTxt li a:hover {
    color: #f54343;
    text-decoration: underline;
}

a:visited {
    color: #004276;
}

a {
    text-decoration: none;
}

a:hover {
    color: #f54343;
}