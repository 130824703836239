.box_06 li {
    width: 300px;
    float: left;
    background: url(http://y1.ifengimg.com/a/2014/1208/ent/y_14.jpg) no-repeat left center;
    padding-left: 14px;
    line-height: 28px;
    height: 28px;
    overflow: hidden;
}

.box_06 li a {
    font-size: 14px;
    color: #2b2b2b;
}

.box_06 li a:hover {
    color: #f54343;
}

.box_06 {
    padding-top: 8px;
}

.clearfix:after {
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}