.box_08 .infoConOneBox {
    padding-left: 10px;
    padding-right: 10px;
    margin-left: -10px;
    margin-top: 16px;
    width: 650px;
}

.box_08 .infoConOneTxt {
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: 18px;
    position: relative;
}

.box_08 .infoConOneBox_pic {
    width: 168px;
    height: 90px;
    float: left;
    margin-right: 20px;
}

.box_08 .infoConOneBox_pic a img {
    display: block;
    width: 160px;
    height: 90px;
}

.box_08 .infoConOneBox_pic a:hover img {
    opacity: 0.6;
    filter: alpha(opacity=60);
    -moz-opacity: 0.6;
}

.box_08 .infoConOneBox h3 {
    height: 60px;
    line-height: 30px;
    overflow: hidden;
    padding-top: 15px;
}

.box_08 .pl {
    position: absolute;
    width: 45px;
    height: 14px;
    right: 0;
    bottom: 9px;
}

.box_08 .pl a {
    background: url(http://y0.ifengimg.com/fe/zx1/images/ly_a9ebd58d.jpg) no-repeat 0 2px;
    width: 25px;
    height: 14px;
    display: block;
    color: #d1d1d1;
    overflow: hidden;
    padding-left: 18px;
    line-height: 14px;
    text-decoration: none;
}

.box_08 .infoConOneBox h3 a {
    font-size: 18px;
    font-family: Microsoft Yahei;
    color: #000;
    font-weight: normal;
}

.box_08 .infoConOneBox h3 a:hover {
    color: #f54343;
    text-decoration: none;
}

.box_08 .infoConOneBox:hover {
    background: #f9f9f9;
    display: block;
}

.more_02 {
    width: 61px;
    float: right;
    background: url(http://y1.ifengimg.com/a/2014/1208/ent/y_20.jpg) no-repeat right center;
    height: 22px;
    line-height: 22px;
    margin-top: 10px;
}

.more_02 a {
    color: #999999;
}

.more_02 a:hover {
    color: #f54343;
}

.pt10 {
    padding-top: 10px;
}

.clearfix:after {
    content: '.';
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}
