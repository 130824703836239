.col_box {
    width: 100%;
    margin: 0 auto;
    height: 40px;
}

.col_navlist {
    width: 1000px;
    margin: 0 auto;
    height: 40px;
    border-bottom: 1px solid #e1e1e1;
    overflow:hidden;
    & ul {
        // background: url(./nav_bg03.gif) left top no-repeat;
        height: 40px;
        overflow: hidden;
        & li {
            font-size: 14px;
            height: 40px;
            line-height: 39px;
            background: url(./nav_bg03.gif) left top no-repeat;
            padding: 0 14px 0 16px;
            float: left;
            & a {
                color: #2b2b2b;
            }
        }
        & li.pa {
            background: none;
            padding-left:0px;
        }
        & a:hover {
            color: red;
            text-decoration: none;
            cursor: pointer;
        }
    }
}

