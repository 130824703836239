.box_07 {
    margin-bottom: 30px;
}

.clearfix:after {
    content: '.';
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}

.box_07_a {
    position: relative;
    width: 650px;
    height: 280px;
}

.box_07_a a img {
    display: block;
    width: 100%;
    height: 280px;
}

.box_07_a a:hover img {
    opacity: 0.6;
    filter: alpha(opacity=60);
    -moz-opacity: 0.6;
}

.bag {
    position: absolute;
    width: 100%;
    height: 30px;
    background: #000;
    filter: alpha(opacity=60);
    -moz-opacity: 0.6;
    opacity: 0.6;
    left: 0px;
    bottom: 0px;
}

.text_02 {
    position: absolute;
    left: 0px;
    bottom: 0px;
    height: 30px;
    line-height: 30px;
    padding-left: 10px;
}

.text_02 a {
    color: #fff;
    font-size: 14px;
}
