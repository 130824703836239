.clearfix:after {
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}

.box_06_title a.more {
    display: block;
    float: right;
    width: 30px;
    height: 7px;
    overflow: hidden;
    background: url(http://y1.ifengimg.com/a/2014/1208/ent/y_12.jpg) no-repeat;
    margin-top: 10px;
}

.box_06_title a.title_word {
    font-size: 16px;
    color: #2b2b2b;
    font-family: Microsoft Yahei;
}

.box_06_title {
    background: url(http://y1.ifengimg.com/a/2014/1208/ent/y_11.jpg) no-repeat left bottom;
    padding-bottom: 10px;
}

.box_06_title a.title_word:hover {
    text-decoration: none;
}

.box_06_title a.more:hover {
    background: url(http://y1.ifengimg.com/a/2014/1208/ent/y_13.jpg) no-repeat;
}