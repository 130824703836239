.box_099 {
    border-left: 1px solid #e0e0e0;
}

.box_099 ul li.bg03 {
    background: #f9f9f9;
}

.box_099 ul li {
    cursor: pointer;
}

.box_099_02 {
    position: relative;
    width: 299px;
    height: 210px;
    overflow: hidden;
}

.text_bg02 {
    position: absolute;
    width: 100%;
    height: 48px;
    background: #fff;
    filter: alpha(opacity=60);
    -moz-opacity: 0.6;
    opacity: 0.6;
    left: 0px;
    bottom: 0px;
}

.text_bg02 {
    position: absolute;
    width: 100%;
    height: 48px;
    background: #fff;
    filter: alpha(opacity=60);
    -moz-opacity: 0.6;
    opacity: 0.6;
    left: 0px;
    bottom: 0px;
}

.text_03 {
    position: absolute;
    left: 15px;
    top: 145px;
    padding: 4px 6px;
    background: #f54343;
    line-height: 22px;
    color: #fff;
    font-size: 18px;
    font-family: Microsoft Yahei;
    font-weight: normal;
    text-decoration: none;
}

.text_03 a {
    color: #fff;
    font-size: 18px;
    font-family: Microsoft Yahei;
    font-weight: normal;
    text-decoration: none;
}

.text_04 {
    position: absolute;
    left: 0px;
    bottom: 0px;
    height: 30px;
    line-height: 30px;
    padding-left: 10px;
    padding-top: 18px;
}

.text_04 a {
    font-size: 14px;
    color: #2b2b2b;
    font-weight: bold;
}

.box_099 ul li.bg04 {
    background: #f1f1f1;
}

.box_099 ul li .box_099_01 {
    height: 43px;
    padding-left: 14px;
    overflow: hidden;
}

.box_099 ul li .box_099_01 h3 {
    height: 24px;
    line-height: 24px;
    padding-top: 9px;
    overflow: hidden;
}

.box_099 ul li .box_099_01 h3 a {
    font-size: 14px;
    color: #000;
    font-weight: normal;
    padding-bottom: 5px;
}

.box_099 ul li .box_099_01 h3 a:hover {
    color: #f54343;
}
