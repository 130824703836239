.fpic06 {
    width: 650px;
    height: 360px;
    position: relative;
    overflow: hidden;
}

.fpic06 .text {
    width: 650px;
    height: 44px;
    background: #000;
    position: absolute;
    bottom: 0px;
    left: 0px;
    filter: alpha(opacity=75);
    opacity: 0.75;
}

.fpic06 [ifeng_ui_pc_sliders-dots] {
    bottom: 17px;
}

.fpic06 p {
    font-size: 14px;
    width: 630px;
    height: 44px;
    line-height: 44px;
    position: absolute;
    bottom: 0px;
    left: 0px;
    padding-left: 20px;
}

.bigPic04 {
    position: relative;
}

.bigPic04 a img {
    display: block;
}

#prevSlide02,
#nextSlide02 {
    width: 36px;
    height: 60px;
    position: absolute;
    top: 70px;
    left: 0px;
    z-index: 10;
    text-align: center;
    cursor: pointer;
}

#prevSlide02 img,
#nextSlide02 img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 12;
}

.btnBg {
    width: 36px;
    height: 60px;
    filter: alpha(opacity=60);
    -moz-opacity: 0.6;
    opacity: 0.6;
    background: #000;
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 11;
    display: none;
}

.btnBgHover {
    width: 36px;
    height: 60px;
    filter: alpha(opacity=100);
    -moz-opacity: 1;
    opacity: 1;
    background: #000;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 11;
    display: none;
}

#nextSlide02 {
    left: 365px;
}

#nextSlide02 img {
    left: 0;
}

.dotList04 {
    position: absolute;
    right: 10px;
    top: 162px;
    height: 9px;
    overflow: hidden;
    width: 88px;
}

.fpic06 [ifeng_ui_pc_sliders-dots-item] {
    width: 20px;
    height: 2px;
    margin: 0 0 0 2px;
    cursor: pointer;
    background: #d3cecb;
    overflow: hidden;
}

.fpic06 [ifeng_ui_pc_sliders-dots-item='current'] {
    background: #f54343;
}

.cWhite a,
.cWhite a:visited,
.cWhite a:hover {
    color: #fff;
}

.fpic06 [ifeng_ui_pc_sliders-dots] div {
    width: 20px;
    height: 5px;
}
.current {
    background: #ff2838 !important;
}
.trans {
    transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -webkit-backface-visibility: hidden;
}
.trans:hover {
    transform: scale(1.04, 1.04);
    -ms-transform: scale(1.04, 1.04);
    -webkit-transform: scale(1.04, 1.04);
}
